<template>
  <div>
    <n-table-view
        ref="table"
        class="table6"
        name="Список"
        :genRequestBuilder="genRequestBuilder"
        :height="height"
        :headers="[
        {text:'ID', value:'id', type:'integer'},
        {text:'Дата', value:'created', type:'dateTime'},
        {text:'Тип', value:'operationGroupType', type:'operationGroupType'},
        {text:'Операции', sortable:false, type:'operationGroupOperations'},
        {text:'Итого', sortable:false, type:'operationGroupTotal'},
        {text:'Аналитика', sortable:false, type:'operationGroupAnalytics'},
      ]"
    >
      <template v-slot:barActions>
        <slot name="barActions"/>
      </template>
      <template v-slot:item="{item, headers}">
        <tr @click="operationGroup=item" :data-id="item.id">
          <td v-for="v in headers" :key="v.text">
            <field-renders :item="item" :header="v" :class="$tools.isEmpty(v.class) ? null : v.class"/>
          </td>
        </tr>
      </template>
    </n-table-view>
    <finance-operation-group :operation-group.sync="operationGroup" @fixed="refresh()"/>
  </div>
</template>

<script>
import FieldRenders from "@/componentsV2/base/FieldRenders.vue";
import NTableView from "@/componentsV2/base/NTableView.vue";
import {
  Entity_AnalyticType,
  Entity_AnalyticValueCredit, Entity_AnalyticValueGroup, Entity_AnalyticValueDebet,
  Entity_Operation,
  Entity_OperationGroup, Entity_OperationGroupType, Entity_OperationType,
} from "../../../EntityStoreCacheService";
import FinanceOperationGroup from "@/views/Finance/FinanceOperationGroup.vue";

export default {
  components: {
    FinanceOperationGroup,
    NTableView,
    FieldRenders,
  },
  props:['height','disabled','genRequestBuilderParams'],
  data: () => ({
    operationGroup:null
  }),
  watch:{
    operationGroup(){
      if(this.operationGroup===null)
        this.$refs.table.load();
    },
  },
  methods:{
    load(){
      this.$refs.table.load();
    },

    genRequestBuilder(page, onPage, sortFields, refresh=false){
      let rb = new Entity_OperationGroup()
          .selects(Entity_OperationGroup.id)
          .selects(Entity_OperationGroup.created)
          .selects(Entity_OperationGroup.comment)

          .selects([Entity_OperationGroup.fixedBy, Entity_OperationGroup.id].join('.'))
          .selects([Entity_OperationGroup.fixedBy, Entity_OperationGroup.created].join('.'))

          .selects([Entity_OperationGroup.fixed, Entity_OperationGroup.id].join('.'))
          .selects([Entity_OperationGroup.fixed, Entity_OperationGroup.created].join('.'))

          .selects([Entity_OperationGroup.reverseBy, Entity_OperationGroup.id].join('.'))
          .selects([Entity_OperationGroup.reverseBy, Entity_OperationGroup.operationGroupType, Entity_OperationGroupType.id].join('.'))
          .selects([Entity_OperationGroup.reverseBy, Entity_OperationGroup.operationGroupType, Entity_OperationGroupType.name].join('.'))

          .selects([Entity_OperationGroup.reverse, Entity_OperationGroup.id].join('.'))
          .selects([Entity_OperationGroup.reverse, Entity_OperationGroup.operationGroupType, Entity_OperationGroupType.id].join('.'))
          .selects([Entity_OperationGroup.reverse, Entity_OperationGroup.operationGroupType, Entity_OperationGroupType.name].join('.'))

          .selects([Entity_OperationGroup.analyticValueGroups, Entity_AnalyticValueGroup.id].join('.'))
          .selects([Entity_OperationGroup.analyticValueGroups, Entity_AnalyticValueGroup.valueName].join('.'))
          .selects([Entity_OperationGroup.analyticValueGroups, Entity_AnalyticValueGroup.value].join('.'))
          .selects([Entity_OperationGroup.analyticValueGroups, Entity_AnalyticValueGroup.analyticType, Entity_AnalyticType.id].join('.'))
          .selects([Entity_OperationGroup.analyticValueGroups, Entity_AnalyticValueGroup.analyticType, Entity_AnalyticType.name].join('.'))
          .selects([Entity_OperationGroup.analyticValueGroups, Entity_AnalyticValueGroup.analyticType, Entity_AnalyticType.sort].join('.'))

          .selects([Entity_OperationGroup.operationGroupType, Entity_OperationGroupType.id].join('.'))
          .selects([Entity_OperationGroup.operationGroupType, Entity_OperationGroupType.name].join('.'))
          .selects([Entity_OperationGroup.operationGroupType, Entity_OperationGroupType.reverseBys, Entity_OperationGroupType.id].join('.'))
          .selects([Entity_OperationGroup.operationGroupType, Entity_OperationGroupType.reverseBys, Entity_OperationGroupType.name].join('.'))

          .selects([Entity_OperationGroup.operations, Entity_Operation.id].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.amount].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.discount].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.count].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.operationType, Entity_OperationType.payType].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.operationType, Entity_OperationType.sourceType].join('.'))

          .selects([Entity_OperationGroup.operations, Entity_Operation.operationType, Entity_OperationType.id].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.operationType, Entity_OperationType.name].join('.'))

          .selects([Entity_OperationGroup.operations, Entity_Operation.analyticValueCredits, Entity_AnalyticValueCredit.valueName].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.analyticValueCredits, Entity_AnalyticValueCredit.value].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.analyticValueCredits, Entity_AnalyticValueCredit.analyticTypeCredit, Entity_AnalyticType.id].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.analyticValueCredits, Entity_AnalyticValueCredit.analyticTypeCredit, Entity_AnalyticType.name].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.analyticValueCredits, Entity_AnalyticValueCredit.analyticTypeCredit, Entity_AnalyticType.sort].join('.'))

          .selects([Entity_OperationGroup.operations, Entity_Operation.analyticValueDebets, Entity_AnalyticValueDebet.valueName].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.analyticValueDebets, Entity_AnalyticValueDebet.value].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.analyticValueDebets, Entity_AnalyticValueDebet.analyticTypeDebet, Entity_AnalyticType.id].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.analyticValueDebets, Entity_AnalyticValueDebet.analyticTypeDebet, Entity_AnalyticType.name].join('.'))
          .selects([Entity_OperationGroup.operations, Entity_Operation.analyticValueDebets, Entity_AnalyticValueDebet.analyticTypeDebet, Entity_AnalyticType.sort].join('.'))

      if(!refresh){
        rb.page(page).onPage(onPage)
        rb = this.genRequestBuilderParams(rb);
        sortFields.forEach(v=>{
          rb.order(v.field, v.isAsc)
        })
      }

      return rb;
    },
    refresh(){
      this.genRequestBuilder(null, null, null, true)
        .filterAnd([Entity_OperationGroup.entity, Entity_OperationGroup.id].join('.')+'=?',this.operationGroup.id)
        .page(1).onPage(1)
        .addStateCallback((v)=>{
          if(v.success)
            this.operationGroup = v.items[0];
        })
        .request();
    }
  }
}
</script>